import revive_payload_client_4sVQNw7RlN from "/home/forge/thelisting.app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/forge/thelisting.app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/forge/thelisting.app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/forge/thelisting.app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/forge/thelisting.app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/forge/thelisting.app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/forge/thelisting.app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/forge/thelisting.app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/forge/thelisting.app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/forge/thelisting.app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/forge/thelisting.app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_Eorgdr12jA from "/home/forge/thelisting.app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import switch_locale_path_ssr_8BKffvaEi5 from "/home/forge/thelisting.app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/home/forge/thelisting.app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import api_QQzOodE9HA from "/home/forge/thelisting.app/plugins/api.js";
import auth_ANExhJHvcR from "/home/forge/thelisting.app/plugins/auth.js";
import bus_GHaWeQPCWC from "/home/forge/thelisting.app/plugins/bus.js";
import click_outside_oytHm5ka8v from "/home/forge/thelisting.app/plugins/click-outside.js";
import ga_V7wqDbvyvO from "/home/forge/thelisting.app/plugins/ga.js";
import global_dY5SAi3BsR from "/home/forge/thelisting.app/plugins/global.js";
import img_error_RL61QrGX5P from "/home/forge/thelisting.app/plugins/img-error.js";
import paddle_KR7A0GEODB from "/home/forge/thelisting.app/plugins/paddle.js";
import sentry_client_shVUlIjFLk from "/home/forge/thelisting.app/plugins/sentry.client.ts";
import touchEvents_qmP2C9BqYZ from "/home/forge/thelisting.app/plugins/touchEvents.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_Eorgdr12jA,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  api_QQzOodE9HA,
  auth_ANExhJHvcR,
  bus_GHaWeQPCWC,
  click_outside_oytHm5ka8v,
  ga_V7wqDbvyvO,
  global_dY5SAi3BsR,
  img_error_RL61QrGX5P,
  paddle_KR7A0GEODB,
  sentry_client_shVUlIjFLk,
  touchEvents_qmP2C9BqYZ
]